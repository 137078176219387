.App {
  text-align: center;
  width: 100%;
  height: 100%;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.4);
}

.input {
  text-align: left;
}

html {
  font-family: avenir, Arial, Helvetica, sans-serif;
  overscroll-behavior: none;
  position: fixed;
  inset: 0px;
}

#root {
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  overflow: hidden scroll;
  overscroll-behavior: none;
  text-align: center;
}

*::-webkit-scrollbar {
  display: none;
  scrollbar-width: none;
  width: 0 !important;
}

[data-theme="light"] {
  --cta: #1976d2;
  --blue: blue;
  --brdr: rgb(165, 164, 164);
  --bg: rgba(245, 245, 245, 1);
  --bga: rgba(245, 245, 245, 0.8);
  --fg: rgba(255, 255, 255, 1);
  --fga: rgba(255, 255, 255, 0.6);
  --fc: #272727;
  --txt: #272727;
  --txti: #ffffff;
  --ltr: #616161;
  --past: rgb(175, 176, 177);
  --grey: #454545;
  --neo: 5px 5px 10px #e4e4e4, -5px -5px 10px #ffffff;
  --neoi: 5px 5px 10px #e4e4e4 inset, -5px -5px 10px #ffffff inset;
  --neod: 8px 8px 16px #353535, -8px -8px 16px #555555;
  --neodi: 8px 8px 16px #353535 inset, -8px -8px 16px #555555 inset;
  --aprv: #9ff2af;
  --subm: #ead492;
  --drft: #e3f2fa95;
  --fld: #efeb9861;
}

[data-theme="dark"] {
  --cta: #77b6f4;
  --blue: rgb(136, 148, 138);
  --brdr: rgb(161, 237, 183);
  --bg: rgb(55, 54, 54);
  --bga: rgba(157, 155, 155, 0.9);
  --fg: rgba(100, 100, 100, 0.9);
  --fga: rgba(100, 100, 100, 0.6);
  --fc: #ffffff;
  --txt: #ffffff;
  --txti: #272727;
  --ltr: whitesmoke;
  --past: rgb(11, 11, 11);
  --grey: #575757;
  --neo: 5px 5px 10px #2f2e2e, -5px -5px 10px #3f3e3e;
  --neoi: 5px 5px 10px #2f2e2e inset, -5px -5px 10px #3f3e3e inset;
  --neod: 8px 8px 16px #666665, -8px -8px 16px #656565;
  --neodi: 8px 8px 16px #575757 inset, -8px -8px 16px #666464 inset;
}


.material-icons {
  display: flex !important;
  position: relative;
  flex-direction: column;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: inherit;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url(/src/fonts/Material_Icons.woff2) format("woff2");
}

.material-icons {
  display: flex !important;
  position: relative;
  flex-direction: column;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/* .material-icons:not(.nohover):hover{
  background: rgba(250, 246, 183, 0.57);
} */
blockquote {
  margin: 0px;
}
div:not(:where(.material-icons, .input, .input *, table *, #clarity-live-panel-root *, .MuiPickersPopper-root *, .MuiDialog-root *)) {
  display: flex;
  position: relative;
  align-self: stretch;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
}

*:not(:where(.input, .input *, input, textarea, mark, .card *)) {
  -webkit-touch-callout: none;
  /* -webkit-user-drag: none; */
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline-style: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  /* -webkit-overflow-scrolling: auto; */
}

input :not(:where(.input *, [class*="edit-"])),
textarea :not(:where(.input *, [class*="edit-"])) {
  -webkit-touch-callout: auto;
  -webkit-user-drag: none;
  -webkit-user-select: auto;
  -khtml-user-select: auto;
  -moz-user-select: auto;
  -ms-user-select: auto;
  user-select: auto;
  outline-style: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}